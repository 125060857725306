import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import first from 'lodash/first'
import split from 'lodash/split'
import className from 'classnames'

const ImageLoader = ({
  src,
  srcSet,
  klass,
  forceActive,
  external = false,
  style,
}) => {
  const $el = React.useRef()
  const [isActive, isActiveSet] = React.useState(false)
  const [isWithinView, isWithinViewSet] = React.useState(false)

  if (typeof IntersectionObserver != 'undefined') {
    const observer = React.useRef(
      new IntersectionObserver(
        (elements) => {
          const intersectionRatio = get(first(elements), 'intersectionRatio')

          if (intersectionRatio > 0) {
            isWithinViewSet(true)
          }
        },
        {
          rootMargin: '200px 0px',
          threshold: Array.from(Array(5)).map((x, i) => i / 4), // Make 5 thresholds just to make sure one of them gets fired.
        }
      )
    )

    React.useEffect(() => {
      if (isWithinView) {
        observer.current.disconnect()

        if (external) {
          const _img = new Image()
          _img.src = src

          _img.onload = () => {
            isActiveSet(true)
          }

          _img.onerror = () => {}
        } else {
          isActiveSet(true)
        }
      }
    }, [isWithinView])

    React.useEffect(() => {
      if ($el.current) {
        observer.current.observe($el.current)
      }

      return () => {
        observer.current.disconnect()
      }
    }, [])
  }

  if (isActive || forceActive) {
    return (
      <picture>
        {!external && (
          <React.Fragment>
            {srcSet && (
              <source
                type='image/webp'
                srcSet={`${first(split(srcSet, '.'))}.webp 2x`}
              />
            )}

            <source
              type='image/webp'
              srcSet={`${first(split(src, '.'))}.webp`}
            />
          </React.Fragment>
        )}

        <img
          className={klass}
          alt={klass}
          src={src}
          srcSet={srcSet}
          style={style}
        />
      </picture>
    )
  }

  return (
    <div
      className={className('ImageLoader', {
        [klass]: klass,
      })}
      ref={$el}
    ></div>
  )
}

ImageLoader.propTypes = {}

export default ImageLoader
